.footer-main{
    /* height: 60vh; */
    padding: 5rem 0 1rem;
    background-color: #0B2643;
    color: white;
}

.footer-logo{
    color: #4E545D;
    font-weight: bold;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.footer-text{
    margin: 1.5rem 0;
    color: rgba(255, 255, 255, 0.87);
}

.footer-boder{
    border-bottom: 1px solid white;
}

.footer-icon-div{
    display: flex;
    gap: 0.6rem;
    margin-bottom: 1rem;
}

.footer-icon{
    border: 1px solid white;
    font-size: 14px;
    padding: 0.5rem 0.7rem;
    border-radius: 8px;
}

.footer-title{
    color: white;
    font-size: 24px;
    font-weight: bold;
    padding: 0.4rem;
}

.footer-icon2{
    color: white;
    padding: 0 1rem 0 0.4rem;
}

.footer-info{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 0.6rem;
}

.footer-info-text{
    color: rgba(255, 255, 255, 0.87);
}

.footer-link-text{
    color: rgba(255, 255, 255, 0.87);
    font-weight: 600;
    margin: 0.6rem 0.5rem;
    cursor: pointer;
}

.send-icon{
    rotate: 60deg;
    color: white;
}

.footer-input{
    width: 85%;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    padding: 0.7rem 1rem;
    color: rgba(255, 255, 255, 0.87);
    font-weight: 600;
}

.footer-input:focus{
    outline: none;
}

.footer-input-div{
    border: 1px solid white;
    width: 95%;
    border-radius: 5px;
    margin: 0 0 1.5rem 0;
}

.footer-copywrite{
    text-align: center;
    margin: 1.5rem;
    color: rgba(255, 255, 255, 0.87);
}

.footer-copywrite a{
    color: rgba(255, 255, 255, 0.87);
    font-weight: bold;
}