.admin-users{
    padding: 0 2rem;
    position: relative;
}

.admin-users-title{
    margin: 1rem 0 2rem;
    font-weight: bold;
    color: #0B2643;
}

.admin-addBtn{
    background-color: #0B2643;
    border: none;
    color: white;
    font-weight: 600;
    padding: 0.6rem 2rem;
    height: 100%;
    border-radius: 2px;
    text-decoration: none;
}

@media only screen and (max-width: 475px) {
    .admin-addBtn{
        padding: 0.6rem 1rem;
    }    
}