.guidelines-main {
    padding: 0 2rem;
}

.dzu-dropzone {
    overflow: auto !important;
}

.guideline-btn {
    background-color: #0B2643;
    /* padding: 0.4rem 1.5rem; */
    /* margin: 2rem 0; */
    width: 180px;
    height: 50px;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: 600;
}

.dzu-inputLabel {
    color: black !important;
    font-weight: 500 !important;
}

.guidelines-title {
    margin: 1rem 0 1rem;
    font-weight: bold;
    color: #0B2643;
}

.custom-datatable2 .rdt_TableHeadRow {
    font-weight: bold;
    background-color: #0B2643;
    color: white;
}

.guidelines-box {
    display: flex;
    gap: 1rem;
}

@media only screen and (max-width: 480px) {
    .guidelines-box {
        display: block;
    }

    .guideline-btn {
        padding: 0.8rem 1.5rem;
        margin: 1rem 0;
        width: 100%;
    }
}