.OurStory-main{
    /* min-height: 100vh; */
    background-color: #0B2643;
    padding: 5rem 0 0 0;
}

.ourstory-video{
    height: 270px;
    width: 270px;
    margin: 2rem 0 ;
}

.ourstory-left-box{
    display: flex;
    gap: 2.5rem;
    justify-content: center;
}



.ourstory-year{
    color: white;
    padding: 1rem 0;
    width: 300px;
}

.year-div{
    background-color: #0086C9;
    width: 100%;
    border-radius: 10px;
    padding: 1rem;
    margin: 1rem 0 4rem;
}

.year-title{
    margin-bottom: 0;
    font-size: 50px;
    font-weight: bold;
    margin-top: -3.6rem;
    color: rgba(255, 255, 255, 0.87);
}

.year-text{
    margin-bottom: 0;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.87);
}

.ourstory-title{
    font-weight: bold;
    font-size: 40px;
    margin: 0 0 2rem;
}
.ourstory-text{
    font-size: 17px;
    color: rgba(255, 255, 255, 0.87);
    margin: 0 0 4rem;
}

.ourstory-btn{
    background-color: white;
    border: none;
    color: #000;
    padding: 0.6rem 1.5rem;
    font-size: 14px;
    font-weight: 600;
    border-radius: 7px;
}



@media only screen and (max-width: 767px) {
    .ourstory-title{
        font-size: 30px;
    }
    .ourstory-text{
        font-size: 16px;
    }
}


@media only screen and (max-width: 575px) {
    .ourstory-left-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ourstory-right-box{
        padding: 0 2rem;
    }

    .ourstory-title{
        font-size: 26px;
    }
}