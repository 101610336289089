.db-home-main {
    padding: 1.5rem;
    position: relative;
}

.db-home-title{
    font-weight: bold;
    color: #0B2643;
    margin: 2rem 0 1rem 0;
}

.db-home-count {
    background-color: #FBFCFE;
    padding: 1.5rem 1.5rem;
    font-weight: 600;
    margin: 1rem 0;
    width: 280px;
    color: #0B2643;
}

.db-home-count .icon-count {
    font-size: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0 0 0;
}



.db-home-box {
    display: flex;
    gap: 2rem;
}
/* Your CSS file or styled-components styles */
/* .custom-datatable .rdt_TableHeader {
    font-weight: 600;
    background-color: red;
  } */
  
  .custom-datatable .rdt_TableHeadRow {
    font-weight: bold;
    background-color: #0B2643;
    color: white;
  }

  /* .custom-datatable .rdt_TableRow {
    cursor: pointer;
} */

/* .custom-datatable .rdt_TableRow:not(.rdt_TableHeader_row) {
    cursor: pointer;
} */
  
/* .custom-datatable .rdt_TableBody {
    cursor: pointer;
} */








@media only screen and (max-width: 991px) {
    .db-home-count {
        width: 220px;
    }
}

@media only screen and (max-width: 767px) {
    .db-home-count {
        width: 80%;
    }

    .db-home-box {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
    }
}

@media only screen and (max-width: 480px) {
    .db-home-count {
        width: 100%;
    }

    .db-home-box {
        display: flex;
        flex-wrap: wrap;
    }
}