.disclaimer-overlay {
    background: rgb(1, 10, 64, 0.3);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    opacity: 1;
    z-index: 10;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    backdrop-filter: blur(0.2rem) saturate(100%);
    animation: 0.7s ease 0s 1 normal forwards running animateOverlay;
}

.disclaimer-modal {
    opacity: 1;
    z-index: 50;
    position: relative;
    width: 100%;
    max-width: 60%;
    height: auto;
    padding: 2rem 2rem 1.5rem 2rem;
    border-radius: 1rem;
    position: relative;
    background: white;
    backdrop-filter: blur(40px);
    /* border: 1px dashed rgba(255, 255, 255, 0.2); */
    /* box-shadow: rgba(255, 255, 255, 0.3) 0px 10px 40px,
        rgb(0, 0, 0) 0px 0px 0px 1px inset; */
    animation: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 normal forwards running animateModel;
}

.disclaimer-modal::-webkit-scrollbar {
    display: none;
}

.disclaimer-modal p {
    font-size: 15px;
    line-height: 20px;
    margin: 0 0 0.1rem;
}


@keyframes animateOverlay {
    0% {
        backdrop-filter: blur(0) saturate(100%);
    }

    100% {
        backdrop-filter: blur(0.2rem) saturate(120%);
    }
}

@keyframes animateModel {
    0% {
        transform: translateX(0px) scale(0.8);
        opacity: 0;
    }

    100% {
        transform: translateX(0px) scale(1);
        opacity: 1;
    }
}


@media only screen and (max-width: 1100px) {
    .disclaimer-modal {
        max-width: 90%;
        height: 90vh;
        overflow: scroll;
    }
}

@media only screen and (max-width: 600px) {
    .disclaimer-modal {
        max-width: 90%;
    }
}