.signup-main {
    /* background-image: url('../../assets/images/login-bg.PNG'); */
    min-height: 40vh;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    /* padding-top: 11rem; */
    z-index: -1;
    color: #0B2643;
    display: flex;
    /* justify-content: center; */
    padding: 9rem 0 0 0;
    align-items: center;
    flex-direction: column;
    background-color: #DBF1FF;
}

.signup-title {
    text-align: center;
    font-weight: bold;
    font-size: 55px;
}

.signup-breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
}

.signup-icon {
    font-size: 12px;
    margin: 0.3rem 0 0 0;
}

.signup-body {
    padding: 5rem 0;
    min-height: 100vh;
    background-color: #DBF1FF;
}


.signup-card {
    background-color: #0B2643;
    border-radius: 1.1rem;
    padding: 6rem 5rem 2rem;
}

.signup-header {
    text-align: center;
    color: white;
    font-size: 55px;
    font-weight: bold;
}

.signup-text {
    text-align: center;
    color: rgba(255, 255, 255, 0.87);
    margin: 1rem 0;
    font-size: 17px;
    /* color: #b2b2b2; */
}


.signup-inp-box {
    padding: 1rem 0;
}

.signup-input {
    width: 100%;
    background-color: white;
    border: none;
    border-radius: 10px;
    /* height: 40px; */
    padding: 12px 20px;
    color: #000;
    font-weight: 600;
    margin: 0.7rem 0;
}

.signup-input2 {
    width: 100%;
    background-color: white;
    border-radius: 10px;
    /* height: 40px; */
    color: #000;
    font-weight: 600;
    margin: 0.7rem 0 1.4rem;
}

.signup-input2 input {
    padding: 12px 20px;
    border-radius: 10px;
    border: none;
    width: 92%;
}

.signup-input2 input:focus {
    outline: none;
    border: 1px solid transparent;
}

.signup-input:focus {
    outline: none;
    border: 1px solid #0B2643;
}

.signup-btn {
    background-color: white;
    width: 100%;
    border: none;
    padding: 11px;
    border-radius: 10px;
    font-weight: 600;
    color: #000;
}
.signup-btn2 {
    background-color: white;
    width: 100%;
    border: none;
    padding: 11px;
    border-radius: 10px;
    /* color: #75758B; */
    margin: 0 0 1rem;
}

.signup-botm-text {
    color: white;
    margin: 1rem 0;
    text-align: center;
    font-weight: 600;
}

.checkbox-div {
    color: rgba(255, 255, 255, 0.87);
    display: flex;
    gap: 1rem;
    margin: 0 0 2rem;
}

.signin-checkbox {
    border-color: orangered;
}

.signup-input-div{
    position: relative;
}

.error-icon{
    position: absolute;
    color: #e4002b;
    top: 40%;
    right: -25px;
}

/* .tooltip {
    width: 360px !important;
  } */








@media only screen and (max-width: 767px) {
    .signup-title {
        font-size: 40px;
    }

    .signup-main {
        min-height: 30vh;
    }

    .signup-card {
        padding: 6rem 1.5rem 2rem;
    }

    .signup-header {
        font-size: 40px;
    }
    .error-icon{
        right: -20px;
    }
}

@media only screen and (max-width: 575px) {
    .signup-body {
        padding: 3rem 0;
    }

    .signup-main {
        padding: 5rem 0 0 0;
    }

    .signup-card {
        padding: 3rem 1.5rem 2rem;
    }
}

@media only screen and (max-width: 480px) {
    .signup-title {
        font-size: 30px;
        margin-top: 2rem;
    }

    .signup-main {
        min-height: 30vh;
    }

    .signup-header {
        font-size: 30px;
    }
}