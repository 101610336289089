.calculator-main {
    padding: 1.5rem;
}

.calculator-body {
    background-color: white;
    border-radius: 0.3rem;
    padding: 2rem 2rem 0;
    border: 1px solid black;
    margin: 0 8.5rem;
}

.calculator-body h2 {
    text-align: center;
    font-weight: bold;
    padding: 0 0 1.5rem 0;
}

.cal-form{
    margin: 0 0 1.5rem;
}

.cal-form p {
    margin: 0 0 0.5rem 0;
    font-size: 17px;
    color: #555555;
}

.cal-form input,
.cal-form select {
    width: 100%;
    border: none;
    border-bottom: 2px solid lightgray;
}

.cal-form input:focus,
.cal-form select:focus {
    outline: none;
}

.cal-radio-btns{
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.cal-radio-btns div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
}

.cal-radio-btns input{
    height: 15px;
    width: 15px;
}

.cal-form span{
    color: #0B2643;
    cursor: pointer;
    font-weight: 600;
}

.cal-form button{
    width: 100%;
    border: none;
    background-color: #0B2643;
    color: white;
    padding: 0.5rem;
    border-radius: 0.3rem;
    font-size: 17px;
    font-weight: 600;
}

.cal-form label{
    /* color: red; */
    font-size: 14px;
    padding: 0.2rem 0;
}

.cal-answer{
    margin: 3rem 0 2rem;
}

.cal-answer div{
    background-color: white;
    min-height: 150px;
    border-radius: 0.3rem;
    padding: 1.5rem;
    border: 1px solid #0B2643;
}

@media only screen and (max-width: 1500px) {
    .cal-form label{
        padding: 0;
    }
}

@media only screen and (max-width: 1330px) {
    .calculator-body {
        margin: 0 3.5rem;
    }
}

@media only screen and (max-width: 1100px) {
    .calculator-body {
        margin: 0 1rem;
    }
}

@media only screen and (max-width: 830px) {
    .calculator-body {
        margin: 0 1rem;
        padding: 2rem 0.5rem 0;
    }
}

@media only screen and (max-width: 767px) {
    .calculator-body {
        padding: 2rem 2rem 0;
    }
}

@media only screen and (max-width: 575px) {
    .calculator-body {
        padding: 2rem 1rem 0;
        margin: 0 0;
    }

    .calculator-main {
        padding: 1.5rem 0.5rem;
    }
}