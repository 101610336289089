.dashboard-main {
    display: flex;
}

.sidebar {
    width: 6%;
    min-height: 100vh;
    transition: width 0.5s ease;
    background-color: #0B2643;
    /* background-color: b; */
}

.sidebar-logo i {
    font-size: 25px;
}

.sidebar.open {
    width: 20%;
    /* width: 300px; */
}

.dashboard-body {
    width: 94%;
    min-height: 101vh;
    transition: width 0.5s ease;
}

.sidebar.open+.dashboard-body {
    width: 80%;
    min-height: 101vh;
}

.sidebar-logo {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(211, 211, 211, 0.226);
    color: white;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.3rem;
}

.sidebar-body {
    padding: 2rem 1.5rem;
}

.sidebar-box {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 20px;
    font-weight: 600;
    margin: 0 0 1rem 0;
    cursor: pointer;
}

.sidebar-box i {
    padding: 0.2rem 0 0 0;
}

.sidebar:not(.open) .sidebar-body .sidebar-box span {
    display: none;
}

.sidebar:not(.open) .sidebar-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sidebar:not(.open) .sidebar-logo {
    font-size: 14px;
    padding: 1rem 0.5rem;
    justify-content: center;
}

.sidebar:not(.open) .sidebar-logo i {
    font-size: 14px;
}

.dashboard-nav {
    padding: 1.1rem 1.5rem;
    border-bottom: 1px solid rgba(211, 211, 211, 0.226);
    display: flex;
    justify-content: space-between;
}

.dashboard-nav i {
    font-size: 35px;
    color: gray;
    cursor: pointer;
}



.custom-toast {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 4px;
}

.message {
    font-size: 14px;
    margin-bottom: 1rem;
}

.button-container-toast {
    display: flex;
    flex-direction: row;
}

.button-toast {
    font-size: 1rem;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.button-toast.yes {
    background-color: #32CD32;
    color: #fff;
}

.button-toast.no {
    background-color: #DC143C;
    color: #fff;
    margin-left: 1rem;
}

.button-toast.yes:hover,
.button-toast.no:hover {
    transform: scale(1.05);
}

.nav-date {
    padding: 0rem 1.5rem;
    display: flex;
    align-items: center;
    font-weight: 600;
    color: black;
    border-right: 2px solid black;
    margin-right: 1.5rem;
}

.nav-img {
    height: 35px;
    width: 35px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
}






@media only screen and (max-width: 1100px) {
    .sidebar-box {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 14px;
        font-weight: 600;
        margin: 0 0 1rem 0;
        cursor: pointer;
    }

    .sidebar-box {
        gap: 0.5rem;
    }

    .sidebar-logo {
        justify-content: center;
        font-size: 25px;
        padding: 1.25rem 1.5rem;
    }

    .sidebar:not(.open) .sidebar-logo {
        font-size: 14px;
        padding: 1rem 0rem;
        justify-content: center;
    }
    
    .sidebar:not(.open) .sidebar-logo i {
        font-size: 14px;
    }
}

@media only screen and (max-width: 991px) {
    .sidebar-logo {
        font-size: 20px;
        padding: 1.5rem 1rem;
    }

    .sidebar-logo i {
        font-size: 20px;
    }

    .sidebar:not(.open) .sidebar-logo {
        font-size: 14px;
        padding: 0.5rem 0rem;
        justify-content: center;
    }
    
    .sidebar:not(.open) .sidebar-logo i {
        font-size: 14px;
    }
}



@media only screen and (max-width: 767px) {
    .sidebar-body .sidebar:not(.open) {
        padding: 2rem 0.1rem;
    }

    .sidebar-body {
        padding: 2rem 0.7rem;
    }

    .sidebar {
        width: 10%;
    }

    .sidebar.open {
        width: 25%;
    }

    .sidebar:not(.open) .sidebar-logo {
        font-size: 14px;
        padding: 1.1rem 0rem;
        justify-content: center;
    }
    
    .sidebar:not(.open) .sidebar-logo i {
        font-size: 14px;
    }

    .sidebar-logo {
        justify-content: center;
    }

    .dashboard-body {
        width: 90%;
        transition: width 0.5s ease;
    }

    .sidebar.open+.dashboard-body {
        width: 75%;
    }
}

@media only screen and (max-width: 650px) {
    .sidebar-box {
        font-size: 10px;
    }

    .sidebar:not(.open) .sidebar-body .sidebar-box {
        font-size: 18px;
    }
}

@media only screen and (max-width: 475px) {
    .sidebar {
        width: 15%;
    }

    .sidebar.open {
        width: 30%;
    }

    .dashboard-body {
        width: 85%;
        transition: width 0.5s ease;
    }

    .sidebar.open+.dashboard-body {
        width: 70%;
    }

    .nav-date {
        padding: 0rem 0.5rem;
        display: flex;
        align-items: center;
        font-weight: 600;
        color: black;
        border-right: 2px solid black;
        margin-right: 1rem;
        font-size: 13px;
    }

    .sidebar-logo {
        font-size: 18px;
        padding: 1rem 1rem;
    }

    .sidebar-logo i {
        font-size: 18px;
    }

    .sidebar:not(.open) .sidebar-logo {
        font-size: 13px;
        padding: 1.1rem 0rem;
        justify-content: center;
    }
    
    .sidebar:not(.open) .sidebar-logo i {
        font-size: 13px;
    }
}