.app-main {
    /* min-height: 100vh; */
    /* background-color: #161313; */
    padding: 5rem 0 0;

}

.app-title {
    font-size: 40px;
    text-align: center;
    color: #0B2643;
    font-weight: bold;
    padding: 1rem 0;
}

.app-text-div {
    color: white;
    display: flex;
    justify-content: center;
}

.app-text {
    width: 55%;
    text-align: center;
    color: #0b2643;
}

.apps-div {
    margin: 5rem 0;
    display: flex;
    flex-wrap: wrap;
    color: white;
    gap: 1rem;
}

.apps-box {
    background-color: #0B2643;
    padding: 2rem 2.5rem;
    width: 240px;
    border-radius: 20px;
    margin-bottom: 2rem;
}

.app-icon-div {
    margin-top: -5.5rem;
    margin-bottom: 1rem;
}

.apps-box:hover {
    padding: 6rem 2.5rem 2rem;
    transition: padding 0.3s ease-in;
}

.apps-icon {
    font-size: 40px;
    background-color: #00BCF7;
    color: white;
    border-radius: 15px;
    padding: 1rem 1.1rem;
}

.apps-img{
    border-radius: 50%;
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.apps-title {
    font-size: 23px;
    font-weight: 600;
    margin-bottom: 0;
}


.apps-text {
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: 0;
}



@media only screen and (max-width: 991px) {
    .apps-box {
        width: 220px;
    }
}

@media only screen and (max-width: 767px) {
    .apps-box {
        width: 250px;
    }
}

@media only screen and (max-width: 556px) {
    .apps-box {
        width: 100%;
    }

    .apps-div {
        justify-content: center;
        margin: 5rem 1rem
    }
}