.stripepayment-overlay {
  background: rgb(1, 10, 64, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  opacity: 1;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  backdrop-filter: blur(0.2rem) saturate(100%);
  animation: 0.7s ease 0s 1 normal forwards running animateOverlay;
}

.stripepayment-modal {
  opacity: 1;
  z-index: 50;
  position: relative;
  width: 100%;
  max-width: 40%;
  height: auto;
  padding: 2rem 2rem 1.5rem 2rem;
  border-radius: 1rem;
  position: relative;
  background: white;
  backdrop-filter: blur(40px);
  /* border: 1px dashed rgba(255, 255, 255, 0.2); */
  /* box-shadow: rgba(255, 255, 255, 0.3) 0px 10px 40px,
      rgb(0, 0, 0) 0px 0px 0px 1px inset; */
  animation: 0.7s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 normal forwards running animateModel;
}

.stripepayment-modal::-webkit-scrollbar {
  display: none;
}

.stripe-close-icon {
  position: absolute;
  top: 1vh;
  right: 0.8vw;
  cursor: pointer;
  opacity: 1;
  z-index: 1;
}

.stripe-close-icon i {
  font-size: 2rem;
  color: #000;
}


.modal-title {
  /* border-bottom: 1px solid black; */
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.modal-body {
  /* height: 360px; */
  /* overflow-y: scroll; */
}

.modal-body-title {
  font-size: 25px;
  font-weight: 600;
}

.modal-body-text {
  font-size: 25px;
}

.modal-body .row {
  padding: 0.3rem 0;
}

.modal-btn {
  width: 100%;
  background-color: #DC3545;
  border: none;
  color: white;
  padding: 0.6rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  font-size: 17px;
  font-weight: 600;
}
.modal-btn2 {
  width: 100%;
  background-color: #0D6EFD;
  border: none;
  color: white;
  padding: 0.6rem;
  border-radius: 5px;
  margin: 0.5rem 0;
  font-size: 17px;
  font-weight: 600;
}

@keyframes animateOverlay {
  0% {
    backdrop-filter: blur(0) saturate(100%);
  }

  100% {
    backdrop-filter: blur(0.2rem) saturate(120%);
  }
}

@keyframes animateModel {
  0% {
    transform: translateX(0px) scale(0.8);
    opacity: 0;
  }

  100% {
    transform: translateX(0px) scale(1);
    opacity: 1;
  }
}


@media only screen and (max-width: 767px) {
  .stripepayment-modal {
    max-width: 60%;
  }
}

@media only screen and (max-width: 600px) {
  .stripepayment-modal {
    max-width: 90%;
  }
}