.wwa-main{
    /* min-height: 100vh; */
    background-color: #0B2643;
    padding: 5rem 0 4rem;
}

.wwa-title{
    text-align: center;
    color: white;
    padding: 1.5rem 0 ;
    font-weight: 600;
    font-size: 41px;
}

.wwa-img{
    display: flex;
    justify-content: center;
    margin: 3rem 0 0 0
}
.wwa-text{
    font-weight: 600;
    color: white;
}
.wwa-span{
    color: #3195D8;
}

.wwa-btn {
    background-color: white;
    border: none;
    color: #000;
    padding: 9px 15px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    cursor:text !important;
}




@media only screen and (max-width: 767px) {
    .wwa-title{
        font-size: 31px;
    }
}