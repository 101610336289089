
#chart {
    max-width: 100%;
    margin: 1rem 0 2rem;
    padding: 20px;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 5px;
  }
  
  .toolbar {
    margin: 0rem 3rem;
  }
  
  .toolbar button {
    padding: 0.1rem 1rem;
    font-size: 16px;
    cursor: pointer;
    background-color: white;
    color:black;
    /* border: none; */
    border: 1px solid lightgray !important;
    /* border-radius: 5px; */
    margin: 0 0.2rem;
  }
  
  .toolbar button.active {
    background-color: #008FFB;
    border: none;
    color: white;
  }
  
  #chart-timeline {
    text-align: center;
  }
  