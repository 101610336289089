.navbar {
    position: fixed;
    background-color: transparent !important;
    padding: 1.5rem 2rem;
    width: 100%;
    transition: all 0.3s;
    z-index: 1000;
}

.nav-logo {
    color: #4E545D;
    padding: 0;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.custom-navbar {
    transition: background-color 0.3s;
}

.custom-navbar.scrolled {
    background-color: white !important;
    padding: 0.8rem 2rem;
}


.nav-link {
    color: #0a2643 !important;
    font-size: 18px;
    font-weight: 600;
}

.nav-item div {
    cursor: pointer;
}

.navbar-nav {
    gap: 2rem;
}

.nav-icon {
    font-size: 14px;
    padding: 0 0 0 0.2rem;
}

.nav-btn {
    background-color: transparent;
    border: 2px solid #0a2643;
    color: #0a2643;
    padding: 0.7rem 2.5rem;
    border-radius: 0.5rem;
    font-weight: 600;
    text-decoration: none;
}

.navbar-toggler {
    border-color: #0a2643 !important;
}

.nav-toggler-icon {
    color: #0a2643;
    font-size: 1.3rem;
    padding: 0.4rem 0.4rem;
}






@media only screen and (max-width: 1200px) {
    .navbar-nav {
        gap: 1.3rem;
    }
}

@media only screen and (max-width: 991px) {
    .nav-bg {
        background-color: #0B2643 !important;
    }

    .nav-link {
        color: white !important;
    }

    .nav-btn {
        border: 2px solid white;
        color: white;
    }

    .nav-toggler-bg {
        border-color: white !important;
    }

    .nav-toggler-icon-clr {
        color: white;
    }
}