.addMedCon-select{
    margin: 1rem 0 0;
}

.addMedCon-select .css-13cymwt-control{
    height: 45px !important;
}

.addMedCon-select .css-13cymwt-control:focus{
    height: 45px !important;
}

.addMedCon-select .css-t3ipsp-control{
    height: 45px !important;
}