.admin-home-main {
    padding: 0rem 2rem;
    position: relative;
}

.admin-home-title{
    font-weight: bold;
    color: #0B2643;
    padding: 1.5rem 0 0rem 0;
}

.admin-home-box {
    display: flex;
    gap: 2rem;
}

.admin-home-count {
    background-color: #FBFCFE;
    /* padding: 1rem 1rem; */
    font-weight: 600;
    margin: 1rem 0;
    width: 180px;
    height: 120px;
    color: #0B2643;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.admin-home-count p {
    font-size: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0.5rem 0 0 0;
}





@media only screen and (max-width: 991px) {
    .admin-home-count {
        width: 220px;
    }
}


@media only screen and (max-width: 767px) {
    .admin-home-count {
        width: 80%;
    }

    .admin-home-box {
        display: flex;
        flex-wrap: wrap;
        gap: 0;
    }
}

@media only screen and (max-width: 480px) {
    .admin-home-count {
        width: 100%;
    }

    .admin-home-box {
        display: flex;
        flex-wrap: wrap;
    }
}