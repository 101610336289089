.admin-addUser {
    padding: 2rem 4rem;
    position: relative;
}

.addUser-pageLoader{
    width: 100%;
    height: 88vh;
    /* background-color: #F5F6F8; */
    position: absolute;
    z-index: +1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-cardHead {
    background-color: #0B2643;
    padding: 1rem 2rem;
}

.admin-card-title {
    color: white;
    /* font-weight: bold; */
}

.admin-cardBtn {
    background-color: white;
    border: none;
    color: black;
    font-weight: 600;
    padding: 0.25rem 1.5rem;
    height: 100%;
    border-radius: 2px;
    text-decoration: none;
}

.admin-cardBody {
    background-color: white;
    padding: 1.5rem 1.5rem 0;
}

.admin-cardBody-title {
    border-bottom: 1px solid rgba(211, 211, 211, 0.555);
    padding: 0 0 0.7rem;
    margin: 0;
    color: #0B2643;
}

.admin-card-label {
    margin: 0.7rem 0;
    font-weight: 600;
    font-size: 17px;
    color: #0B2643;
}

.admin-card-input {
    width: 95%;
    border: 1px solid lightgray;
    padding: 0.3rem 1rem;
    border-radius: 2px;
    color: gray;
    font-size: 15px;
}

.admin-card-input2 {
    width: 95%;
    border: 1px solid lightgray;
    padding: 0.3rem 1rem;
    border-radius: 2px;
    color: gray;
    font-size: 15px;
}

.admin-card-input2 input {
    width: 95%;
    border: none;
}

.admin-card-input2 input:focus {
    outline: none;
}

.admin-card-input:focus {
    outline: none;
}

.admin-card-input::placeholder {
    font-size: 15px;
}

.inpt2 {
    /* width: 74% !important; */
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: white !important;
}

.admin-card-uploadBtn {
    display: flex;
    width: 95%;
}

.admin-card-Btn2 {
    border: 1px solid #AB8CE4;
    background-color: #AB8CE4;
    color: white;
    padding: 0 1.3rem;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.admin-card-btn-div {
    margin: 1.5rem 0;
    padding: 1.5rem 0;
    border-top: 1px solid rgba(211, 211, 211, 0.555);
}

.admin-cardBtn3 {
    border: none;
    background-color: #0B2643;
    padding: 0.4rem 1rem;
    color: white;
    font-weight: 600;
}

.admin-card-div{
    position: relative;
}
.admin-error-icon{
        position: absolute;
        color: #e4002b;
        top: 25%;
        right: -5px;
}


@media only screen and (max-width: 1330px) {
    .admin-card-input2 input {
        width: 90% !important;
    }
}

@media only screen and (max-width: 991px) {
    .admin-card-input2 input {
        width: 85% !important;
    }
}


@media only screen and (max-width: 575px) {
    .admin-addUser {
        padding: 2rem 1rem;
    }

    .admin-cardHead {
        background-color: #0B2643;
        padding: 1rem;
    }

    .admin-card-input2 input {
        width: 95%;
        border: none;
    }
}