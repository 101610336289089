.adminPanel-main {
    display: flex;
}

.adminPanel-sidebar {
    width: 6%;
    min-height: 100vh;
    transition: width 0.5s ease;
    background-color: #0B2643;
    z-index: +1;
    /* background-color: b; */
}

.adminPanel-sidebar-logo i {
    font-size: 25px;
}

.adminPanel-sidebar.open {
    width: 20%;
    min-height: 101vh;
}

.adminPanel-body {
    width: 94%;
    min-height: 101vh;
    transition: width 0.5s ease;
    background-color: #F5F6F8;
}

.adminPanel-sidebar.open+.adminPanel-body {
    width: 80%;
}

.adminPanel-sidebar-logo {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.212);
    color: white;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-wrap: wrap;
    gap: 0.3rem;
    margin: 0 0 0;
}

/* .adminPanel-sidebar-body {
    padding: 2rem 1.5rem;
} */

.adminPanel-sidebar-box {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 20px;
    font-weight: 600;
    /* margin: 0 0 1rem 0; */
    cursor: pointer;
    padding: 0.8rem 1.5rem;
    border-bottom: 1px solid rgba(128, 128, 128, 0.212);
    width: 100%;
}

/* .adminPanel-sidebar-box i {
    padding: 0.1rem 0 0 0;
} */


.adminPanel-sidebar:not(.open) .adminPanel-sidebar-body .adminPanel-sidebar-box span {
    display: none;
}

.adminPanel-sidebar:not(.open) .adminPanel-sidebar-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
    font-size: 17px;
    padding: 1.5rem 0.4rem;
    justify-content: center;
}

.adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo i {
    font-size: 17px;
}

.adminPanel-nav {
    border-bottom: 1px solid rgba(211, 211, 211, 0.226);
    display: flex;
    justify-content: space-between;
    z-index: 1000;
    background-color: #FFFFFF;
    color: #fbfbfb36;
}

.adminPanel-nav i {
    font-size: 35px;
    color: gray;
    cursor: pointer;
    padding: 1.1rem 1.5rem;
}


.nav-img {
    height: 35px;
    width: 35px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
}

.admin-nav-icon {
    font-size: 20px !important;
    border-left: 1px solid rgba(211, 211, 211, 0.781);
    border-right: 1px solid rgba(211, 211, 211, 0.781);
    height: 100%;
}

.nav-profile {
    padding: 0.7rem 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.7rem;
}

.nav-profile span {
    font-weight: 600;
}

.sidebar-title-marker-on {
    background-color: white;
    height: 54px;
    width: 3px;
}






@media only screen and (max-width: 1700px) {
    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
        font-size: 15px;
        padding: 1rem 0.4rem;
        justify-content: center;
    }

    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo i {
        font-size: 15px;
    }
}

@media only screen and (max-width: 1206px) {
    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
        font-size: 14px;
        padding: 1rem 0.2rem;
        justify-content: center;
    }

    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo i {
        font-size: 15px;
    }
}

@media only screen and (max-width: 991px) {
    .adminPanel-sidebar-logo {
        justify-content: center;
        padding: 1.3rem 1rem;
        font-size: 23px;
    }
    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
        font-size: 13px;
        padding: 1rem 0.1rem;
        justify-content: center;
    }

    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo i {
        font-size: 15px;
    }
}

@media only screen and (max-width: 900px) {
    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
        font-size: 13px;
        padding: 0.6rem 0.1rem;
        justify-content: center;
    }

    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo i {
        font-size: 15px;
    }

    .adminPanel-sidebar-box {
        padding: 0.8rem 1rem;
    }
}


@media only screen and (max-width: 767px) {

    .adminPanel-sidebar-box {
        font-size: 14px;
    }

    .adminPanel-sidebar-box {
        gap: 0.5rem;
    }

    .adminPanel-sidebar {
        width: 10%;
    }

    .adminPanel-sidebar.open {
        width: 25%;
    }

    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
        padding: 1rem 0rem;
        font-size: 14px;
    }

    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo i {
        font-size: 14px;
    }

    .adminPanel-sidebar-logo i {
        font-size: 23px;
    }

    .adminPanel-body {
        width: 90%;
        transition: width 0.5s ease;
    }

    .sidebar.open+.adminPanel-body {
        width: 75%;
    }
}

@media only screen and (max-width: 650px) {
    .adminPanel-sidebar-box {
        font-size: 20px;
    }

    .adminPanel-sidebar .adminPanel-sidebar-body .adminPanel-sidebar-box span {
        display: none;
    }

    .adminPanel-sidebar-box {
        justify-content: center;
    }

    .adminPanel-sidebar {
        width: 15% !important;
    }

    .adminPanel-sidebar.open {
        width: 15% !important;
    }

    .adminPanel-body {
        width: 85% !important;
    }

    .sidebar.open+.adminPanel-body {
        width: 85% !important;
    }

    .adminPanel-sidebar-logo {
        font-size: 14px;
        padding: 1.6rem 0.1rem;
    }

    .adminPanel-sidebar-logo i {
        font-size: 15px;
    }

    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
        padding: 1.6rem 0rem;
    }
}

@media only screen and (max-width: 528px) {
    .adminPanel-sidebar-logo {
        padding: 1.6rem 0rem;
        font-size: 13px;
    }
    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
        padding: 1.6rem 0rem;
        font-size: 13px;
    }
}

@media only screen and (max-width: 480px) {
    .adminPanel-sidebar-logo {
        padding: 1.2rem 0rem;
        font-size: 13px;
    }

    .adminPanel-sidebar-logo i {
        font-size: 13px;
    }

    .adminPanel-sidebar:not(.open) .adminPanel-sidebar-logo {
        padding: 1.2rem 0rem;
        font-size: 13px;
    }

    .nav-profile {
        padding: 0.7rem 1rem;
        gap: 0.7rem;
    }
}