.reco-main{
    padding: 1.5rem;
}

.reco-inp-label{
    font-size: 17px;
    padding: 1rem 0 0.5rem;
}

.reco-radio{
    display: flex;
    align-items: center;
    height: 40px;
    gap: 0.5rem;
}

.reco-btn{
    background-color: #3F79DA;
    padding: 0.4rem 1.5rem;
    margin: 2rem 0;
    border: none;
    color: white;
    border-radius: 5px;
}

.add-btn{
    text-decoration: none;
    background-color: #0B2643;
    padding: 0.5rem 1.5rem;
    margin: 2rem 0;
    border: none;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    font-weight: 600;
}

.reco-head{
    display: flex;
    justify-content: space-between;
}

.db-reco-title{
    margin: 2rem 0 1rem 0;
    color: #0B2643;
    font-weight: bold;
}

.reco-card{
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    padding: 2rem;
    margin: 1rem 0;
    border-radius: 0.2rem;
}




@media only screen and (max-width: 1200px) {
    .reco-card-2{
        display: block !important;
    }
    
}

@media only screen and (max-width: 767px) {
    .reco-head{
        display: flex;
        flex-direction: column;
    }  
    .db-reco-title{
        margin: 2rem 0 0rem 0;
    } 
    .reco-card{
        padding: 1rem;
    }
    .reco-card-2{
        display: flex !important;
    }
}

@media only screen and (max-width: 480px) {
    .reco-card-2{
        flex-direction: column;
    }
    .reco-main{
        padding: 1rem;
    }
}