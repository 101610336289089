.dashboard-profile {
    padding: 1.5rem;
    position: relative;
}

.dashboardprofile-btn {
    background-color: white;
    padding: 1rem 1rem;
    border-radius: 5px;
    margin: 0 0 1rem;
    /* cursor: pointer; */
}

.dashboardprofile-btn h4 {
    font-weight: 700;
    color: #0B2643;
}

.dashboardprofile-btn p {
    margin-bottom: 0;
    font-weight: 600;
}

.dashboardprofile-imgSection {
    background-color: white;
    border-radius: 5px;
    padding: 0 3rem;
    height: 130px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0 0 1rem;
}

.dash-profile-img {
    height: 90px;
    width: 90px;
    border-radius: 50%;
    object-fit: cover;
}

.dashprofile-btnSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.dashprofile-btnSection div h5{
    color: #0B2643;
}
.dashprofile-btnSection div p{
    color: #0B2643;
}

.dashboardprofile-imgSection p {
    font-weight: 600;
    margin-bottom: 0;
}

.dashprofile-updateBtn {
    background-color: transparent;
    border: 1px solid #0B2643;
    padding: 0.5rem 1.5rem;
    height: 100%;
    border-radius: 5px;
    font-weight: 600;
    color: #0B2643;
}

.dashprofile-formSectin{
    background-color: white;
    border-radius: 5px;
    padding: 2rem 3rem;
    /* height: 56vh; */
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.dashprofile-formSectin h4{
    color: #0B2643;
    font-weight: bold;
    margin-bottom: 1rem;
}

.dp-form-label{
    /* font-size: 17px; */
    margin-bottom: 0.3rem;
}
.dp-form-input{
    border: 1px solid #0B2643;
    margin: 0 0 0.7rem;
}
.dp-form-input2{
    border: 1px solid #0B2643;
    margin: 0 0 0.7rem;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.dp-form-input2 input {
    border: none;
    width: 90%;
}

.dp-form-input2 input:focus{
    outline: none;
    border: 1px solid transparent !important;
}
.dp-form-btn{
    background-color: #0B2643;
    border: none;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    padding: 0.6rem 0;
}

.dp-form-div{
    position: relative;
}

.dp-form-icon{
    position: absolute;
    color: #e4002b;
    top: 30%;
    right: -20px;
}




@media only screen and (max-width: 1100px) {
    .dashboardprofile-imgSection {
        padding: 0 2rem;
    }
}

@media only screen and (max-width: 767px) {
    .dashboardprofile-imgSection {
        padding: 2rem 2rem;
        flex-direction: column;
        height: auto;
    }
}

@media only screen and (max-width: 575px) {
    .dashboardprofile-imgSection {
        padding: 1rem;
        flex-direction: column;
        height: auto;
    }
    .dashprofile-btnSection{
        flex-direction: column;
        gap: 1rem;
    }
    .dash-profile-img {
        height: 70px;
        width: 70px;
    }
    .dashprofile-formSectin{
        padding: 2rem 1.2rem;
    }
}