.pa-main{
    padding: 5rem;
}

.pa-body{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
}

.pa-input{
    width: 300px;
}
.pa-input:focus{
    box-shadow: none;
    border: 1px solid black;
}

.pa-backBtn{
    padding: 2rem 3rem 0;
    font-size: 20px;
    position: absolute;
    cursor: pointer;
}

.pa-error-icon{
    position: absolute;
    color: #DC3545;
    top: 30%;
    left: 310px;
}

@media only screen and (max-width: 575px) {
    .pa-main{
        padding: 2rem;
    }
    .pa-body{
        flex-direction: column;
    }
    .pa-input{
        width: 95%;
    }
    .pa-backBtn{
        padding: 2rem 2rem 0;
        font-size: 20px;
        position: relative;
    }
    .pa-error-icon{
        position: absolute;
        color: #DC3545;
        top: 30%;
        /* left: 300px; */
        left: auto;
        right: -10px;
    }
}