.addPatient-main{
    padding: 1.5rem;
    position: relative;
}

.addPatient-inp-label{
    font-size: 17px;
    padding: 1rem 0 0.5rem;
    font-weight: 600;
    color: #0B2643;
}

.addPatient-radio{
    display: flex;
    align-items: center;
    height: 40px;
    gap: 0.5rem;
}

.addPatient-btn{
    background-color: #0B2643;
    padding: 0.4rem 1.5rem;
    margin: 2rem 0;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: 600;
}

.db-addNew-title{
    margin: 2rem 0 1rem 0;
    color: #0B2643;
    font-weight: bold;
}

.addPatient-backBtn{
    /* padding: 2rem 3rem 0; */
    font-size: 20px;
    position: absolute;
    cursor: pointer;
}

.addPatient-pageLoader{
    width: 100%;
    height: 88vh;
    background-color: white;
    position: absolute;
    z-index: +1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ap-div{
    position: relative;
}

.ap-div2{
    position: relative;
    display: flex;
    gap: 1rem;
}

.ap-icon{
    position: absolute;
    color: #e4002b;
    top: 30%;
    right: -20px;
}
.ap-icon2{
    position: absolute;
    color: #e4002b;
    top: 20%;
    right: 10%;
}

.form-control:focus{
    box-shadow: none !important;
    border: 1px solid black;
}

.form-select:focus{
    box-shadow: none !important;
    border: 1px solid black;
}

.css-13cymwt-control{
    border-radius: 0.375rem !important;
    border-color: #dee2e6 !important;
}

.css-t3ipsp-control{
    border-color: black !important;
    box-shadow: none !important;
}

.css-13cymwt-control:focus{
    border-color: #dee2e6 !important;
    box-shadow: none !important;
}

.rti--container{
    border-radius: 0.375rem !important;
    padding: 0.4rem 0.5rem !important;
    border-color: #dee2e6 !important;
}

.rti--input{
    width: 100%;
}

.rti--container:focus-within{
    box-shadow: none !important;
    border-color: black !important;
}

@media only screen and (max-width: 500px) {
    .ap-icon2{
        right: 0;
    }
    .ap-div2{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}