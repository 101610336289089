/* .faq-main{
    height: 100vh;
} */

.faq-left-img{
    background-image: url('../../assets/images/faq-bg.png');
    /* min-height: 150vh; */
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    /* z-index: -1; */
    color: white;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.faq-discover{
    /* border: 1px solid black; */
    display: flex;
    padding: 3rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.226);
}
.faq-discover-title{
    font-size: 24px;
    font-weight: 600;
}

.faq-discover-text{
    font-weight: 600;
}

.faq-discover-btn{
    background-color: transparent;
    border: 1px solid white;
    color: white;
    font-weight: 600;
    border-radius: 10px;
    padding: 0.3rem 1.2rem;
    text-decoration: none;
    cursor: pointer;
}

.discover-left{
    border-right: 1px solid white;
}
.discover-right{
    padding: 0 0 0 1rem;
}

.faq-right{
    /* background-color: #1b1b1b; */
    height: 100%;
    padding: 5rem 2rem 0;
}

.faq-title{
    font-size: 45px;
    font-weight: bold;
    color: #0B2643;
    padding: 0.5rem 0 1rem;
}
.faq-text{
    color: #0B2643;
    font-weight: 600;
}

.faq-accordion{
    padding: 4rem 0;
}

.accordion-button{
    background-color: #0B2643 !important;
    color: white !important;
    /* padding: 1rem 2rem !important; */
    font-size: 24px !important;
    font-weight: bold;
    padding-left: 2.5rem !important;
    padding-top: 2rem !important;
    padding-bottom: 0rem !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
}

.accordion-button.collapsed{
    border-radius: 10px !important;
    background-color: #0B2643 !important;
    padding-bottom: 2rem !important;
}


.accordion-body{
    background-color: #0B2643 !important;
    color: white !important;
    padding-left: 2.5rem !important;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.accordion-item {
    margin-bottom: 1rem;
    border-radius: 10px !important;
    border: 1px solid #2a2a2ab9 !important;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
}

.accordion-button::after {
    /* content: url('path/to/your-custom-expanded-icon.png');  */
    content: none !important;
}

.accordion-button.collapsed::after {
    /* content: url('path/to/your-custom-collapsed-icon.png');  */
    content: none !important;
}










@media only screen and (max-width: 991px) {
    .faq-left-img{
        min-height: 150vh;
    }
}



@media only screen and (max-width: 480px) {
    .faq-discover{
        display: block;
        padding: 3rem 1.5rem;
    }
    .discover-left{
        border-right: none
    }
    .discover-right{
        padding: 1rem 0;
    }
    .faq-title{
        font-size: 30px;
    }
}