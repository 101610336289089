.hero-main {
    /* background-image: url('../../assets/images/hero-bg.PNG'); */
    /* min-height: 200vh; */
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 11rem;
    /* z-index: -1; */
    color: white;
}

.hero-btn {
    background-color: #0B2643;
    border: none;
    color: white;
    padding: 9px 15px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    cursor:text !important;
}

.hero-title {
    padding: 1rem 0;
    font-size: 50px;
    font-weight: bold;
    width: 80%;
    color: #0B2643;
}

.hero-span {
    /* background-color: #e34d00; */
    border-left: 3px solid #3195D8;
    background: #3195D8;
    background: -webkit-linear-gradient(to left, #3195d828, #3195d8af);
    background: linear-gradient(to left, #3195d828, #3195d8af);
    color: #3195D8;
}

.hero-input-box {
    margin: 3rem 0 1.5rem 0;
    width: 74%;
    background-color: white;
    border: 1px solid #0B2643;
    padding: 0.7rem 0.6rem;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-input {
    width: 100%;
    padding: 0.7rem 2rem;
    border: none;
    background-color: transparent !important;
}

.hero-input::placeholder {
    font-weight: 600;
}

.hero-icon {
    rotate: 45deg;
}

.hero-icon-box {
    background-color: #0B2643;
    padding: 10px 25px;
    font-size: 25px;
    border-radius: 12px;
    cursor: pointer;
    z-index: +1;
}


.hero-li::marker {
    color: #0B2643;
}

.hero-li {
    text-decoration: underline;
    color: #0b2643;
}

.hero-portfolio {
    margin: 6rem 0;
    display: flex;
    gap: 1.5rem;
    color: #0B2643;
}

.portfolio-title {
    font-size: 40px;
    font-weight: bold;
}

.protofolio-text {
    font-weight: 600;
    font-size: 14px;
}

.hero-video {
    width: 700px;
}


@media only screen and (max-width: 1300px) {
    .hero-video {
        width: 500px;
    }
}

@media only screen and (max-width: 991px) {
    .hero-video {
        width: 500px;
    }

    .hero-title {
        width: 100%;
        text-align: center;
        font-size: 40px;
    }

    .hero-portfolio {
        display: flex;
        margin: 4rem 0;
        justify-content: space-evenly;
    }

    .hero-li {
        text-align: center;
    }

    .hero-input-div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .hero-btn-div {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 767px) {
    .hero-video {
        width: 100%;
    }

    .hero-title {
        font-size: 30px;
    }

    .hero-portfolio {
        margin: 4rem 0;
    }

    .portfolio-title {
        font-size: 30px;
    }
}

@media only screen and (max-width: 480px) {
    .hero-video {
        width: 100%;
    }

    .hero-title {
        font-size: 26px;
    }

    .hero-portfolio {
        margin: 4rem 0;
    }

    .portfolio-title {
        font-size: 40px;
    }

    .hero-portfolio {
        margin: 3rem 0;
        display: block;
        text-align: center;
    }

    .hero-input {
        padding: 0.7rem 1rem;
    }

    .hero-input-box {
        width: 90%;
    }
}