.medcon-main {
    padding: 0 2rem;
    position: relative;
}

.medcon-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.medcon-btn {
    background-color: #0B2643;
    padding: 0.4rem 2rem;
    margin: 1.5rem 0;
    border: none;
    color: white;
    border-radius: 5px;
    font-weight: 600;
}

.medCon-input {
    height: 45px;
}

.medcon-title {
    margin: 1rem 0 1.5rem;
    font-weight: bold;
    color: #0B2643;
}

.med-error-icon {
    position: absolute;
    color: #e4002b;
    top: 35%;
    right: -20px;
}

.medCon-table div nav {
    display: none !important;
}

/* .medCon-table .custom-datatable2 .rdt_TableBody .rdt_TableRow{
    background-color: #F5F6F8 !important;
    border: 1px solid black !important;
    margin: 0.5rem 0 !important;
    padding: 1rem 0 !important;
    border-radius: 5px;
    font-size: 15px;
}

.medCon-table .custom-datatable2 .rdt_TableBody .rdt_TableRow:hover{
    cursor: pointer;
    background-color: #ECECEC !important;
} */

.medCon-table .custom-datatable2 .rdt_TableBody .rdt_TableCell {
    background-color: transparent !important;
    padding: 1rem 0 !important;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
}

.medCon-table .custom-datatable2 .rdt_TableBody .rdt_TableRow {
    border: 1px solid black !important;
    margin: 0.5rem 0 !important;
    border-radius: 5px;
    padding: 0 !important;
    background-color: #F5F6F8 !important;
}

.medCon-table .custom-datatable2 .rdt_TableBody .rdt_TableRow:hover {
    cursor: pointer;
    background-color: #ECECEC !important;
}

.medCon-table .custom-datatable2 .rdt_TableBody {
    background-color: #F5F6F8 !important;
}

.medCon-table .custom-datatable2 .rdt_TableHeadRow .rdt_TableCol_Sortable {
    margin: 0.2rem 0 !important;
    padding: 1rem 0 !important;
    font-size: 15px;
}

/* .medCon-table .custom-datatable2 .rdt_TableHeadRow{
    display: none !important;
} */

.medCon-addBtn {
    background-color: #0B2643;
    border: none;
    color: white;
    font-weight: 600;
    padding: 0.6rem 1.5rem;
    height: 100%;
    border-radius: 3px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

@media only screen and (max-width: 475px) {
    .medcon-head {
        display: block;
    }

    .medCon-addBtn {
        padding: 0.6rem 1.5rem;
        margin: 0 0 1rem;
    }

    .medcon-title {
        margin: 1rem 0;
    }
}