.ball {
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    border: 1px solid white;
    padding: 1rem;
    top: 50%;
    left: 50%;
}

.ball-text:hover {
    background-color: white !important;
}

.video-main {
    position: relative;
}

.ball-text {
    background-color: white;
    width: 114px;
    height: 114px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}



@media only screen and (max-width: 575px) {
    .vm-img{
        height: 30vh;
    }
}