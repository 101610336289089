.patientdetail-main {
    padding: 1rem 1.5rem;
    background-color: #E8F1F7;
    /* min-height: 88vh; */
    position: relative;
}

.patientdetail-main .container-fluid .row .col {
    padding: 0 0.3rem !important;
}

.pd-sidebar-profile,
.pd-sidebar-disease {
    padding: 0.5rem 0.8rem;
    background-color: white;
    border-radius: 0.5rem;
    margin: 0 0 0.8rem 0;
}

.pd-sidebar-head {
    padding: 1rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pd-sidebar .row {
    padding: 0.5rem 0;
}

.pd-sidebar-title {
    font-weight: 600;
    font-size: 18px;
}

.pd-sidebar-text {
    font-size: 16px;
}

.pd-body {
    background-color: white;
    /* min-height: 70vh; */
    border-radius: 0.5rem;
    padding: 1rem;
    margin: 0 0 0.8rem 0;
}

.pd-body-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pd-body-title {
    font-size: 23px;
    font-weight: 600;
    padding: 0 0.5rem 0;
}

.pd-body-content {
    padding: 0.4rem 0.5rem;
}

.pd-body-gptRes {
    background-color: white;
    margin: 0.8rem 0;
    border-radius: 0.5rem;
    padding: 1rem;
}

.pd-body-text {
    padding: 1rem 0;
}

.patientdetail-icon {
    font-size: 20px;
    padding: 0 0.5rem 1rem;
    cursor: pointer;
}

.pd-btn {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    padding: 2rem;
    margin: 0 0 1rem 0;
    border-radius: 0.5rem;
    /* background-color: #DC3545; */
    background-color: #2ECC71;
    color: white;
    border: none;
    font-size: 20px;
}

.pd-responseTable {
    width: 100%;
    border: 1px solid #dee2e6;
}

.pd-responseTable thead {
    background-color: #0B2643;
    color: white;
}

.pd-responseTable thead tr th {
    padding: 0.8rem 2rem;
    /* border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem; */
    /* border: 1px solid #0B2643; */
}

.pd-responseTable thead tr th div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.pd-responseTable {
    width: 100%;
    border: 1px solid #dee2e6;
}

.pd-responseTable tbody {
    background-color: white;
    color: black;
    padding: 1rem 2rem;
}

.pd-responseTable thead tr th {
    text-align: center;
}

.pd-responseTable tbody tr td {
    padding: 0.4rem 1rem;
    /* text-align: center; */
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.356);
    border-top: none;
}

.pd-custom-datatable {
    border-top-right-radius: 0.5rem !important;
    border-top-left-radius: 0.5rem !important;
}

.pd-table-footer nav {
    border-bottom-right-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
}

.pd-col1{
    width: 10%;
}
.pd-col2{
    width: 20%;
}
.pd-col3{
    width: 50%;
}
.pd-col4{
    width: 20%;
}


.vpr-btn2 {
    background-color: white;
    border: none;
    padding: 1rem;
    width: 100%;
    border-radius: 5px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border: 1px solid lightgray;
    color: black;
    margin: 0 0 0.7rem;
    font-size: 18px;
}

.vpr-btn2:hover {
    background-color: #0B2643;
    color: white;
    transition: 0.3s;

}


.pd-select{
    height: 45px;
    border-color: rgb(118, 118, 118);
    margin: 0.7rem 0 0;
}

.pd-textArea{
    margin: 0.9rem 0 0.7rem;
    width: 100%;
    border-radius: 5px;
    height: 100px;
    padding: 0.8rem;
}

.pd-textArea:focus{
    outline: none;
    border-color: black;
}

.pd-getReco-btn {
    width: 200px;
    padding: 0.6rem;
    border: none;
    background-color: #2ECC71;
    border-radius: 5px;
    color: white;
}

.getReco-Loader {
    background: transparent;
    backdrop-filter: blur(10px);
    gap: 20px;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: +1;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.getReco-title{
    font-size: 40px;
    font-weight: 600;
}
.getReco-Load{
    color: #0B2643;
}

.pd-table-div{
    overflow-x: scroll;
}

.pd-table-div::-webkit-scrollbar {
    width: 5px;
}

.pd-disclaimer{
    font-size: 14px;
    display: flex;
    gap: 0.4rem;
}

.pd-disclaimer p{
    font-weight: bold;
    color: red;
    padding: 0;
}

@media only screen and (max-width: 1150px) {
    .vpr-btn2 {
        padding: 1rem 0.3rem;
        font-size: 16px;
    }

    .getReco-title{
        font-size: 30px;
    }
}


@media only screen and (max-width: 991px) {
    .vpr-btn2 {
        width: 100%;
        margin: auto auto 1rem;
        padding: 1rem;
    }
    .getReco-title{
        font-size: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .pd-btn {
        height: 100px;
        width: 100%;
        font-size: 17px;
        font-weight: 600;
    }

    .pd-getReco-btn {
        width: 100%;
    }

    .getReco-title{
        font-size: 18px;
    }
    
    .pd-responseTable{
        width: 900px;
    }
}

@media only screen and (max-width: 565px) {
    .pd-body-box {
        display: block;
    }

    .pd-body-title {
        font-size: 20px;
        font-weight: 600;
        padding: 0 0.5rem 0;
        margin: 0 0 0.3rem !important;
    }

    .vpr-btn2 {
        padding: 1rem 0.5rem;
    }

    .getReco-Loader {
        flex-direction: column;
    }

    .getReco-title{
        font-size: 20px;
        text-align: center;
    }
}