.db-viewRes-main {
    padding: 0rem 2rem;
}

.vr-btn-box {
    position: relative;
}

.vr-eye-btn {
    color: #0B2643;
    font-size: 18px;
    cursor: pointer;
}

.vr-trash-btn {
    color: #DC3545;
    font-size: 18px;
    cursor: pointer;
}


.custom-datatable::-webkit-scrollbar {
    width: 5px !important;
}

.rdt_TableCol {
    justify-content: center !important;
}

.rdt_TableCell {
    justify-content: center !important;
}

.vr-table {
    overflow-x: scroll;
}

.vr-table-div {
    width: 100%;
}

.vr-table::-webkit-scrollbar {
    width: 5px;
}

.vr-table-div thead {
    background-color: #0B2643;
    color: white;
}

.vr-table-div thead tr th {
    padding: 0.8rem 0.1rem;
    text-align: center;
}

.vr-table-div thead tr th div {
    display: flex;
    justify-content: space-between;
    width: 100%;
}


.vr-table-div tbody {
    background-color: white;
    color: black;
    padding: 1rem 2rem;
}

.vr-table-div tbody tr{
    cursor: pointer;
}

.vr-table-div tbody tr td {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.356);
    border-top: none;
}

.vr-col1 {
    padding: 1rem 0.5rem;
    width: 13%;
}

.vr-col2 {
    padding: 1rem 0.5rem;
    width: 23%;
}

.vr-col3 {
    padding: 1rem 0.5rem;
    width: 17%;
}

.vr-col4 {
    padding: 1rem 0.5rem;
}


@media only screen and (max-width: 1100px) {
    .vr-table-div {
        width: 1250px;
    }
}

.pagination-buttons {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .pagination-buttons button {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    background-color: #0B2643;
    color: #fff;
    border: none;
    border-radius: 4px;
    outline: none;
    font-weight: 600;
  }

  .pagination-buttons button:hover{
    border: 1px solid #0B2643;
  }
  
  .pagination-buttons button:disabled {
    background-color: #bdc3c7;
    color: black;
    cursor: not-allowed;
  }
  